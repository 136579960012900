@media (min-width: 512px) {
    .comments-form {
        width: 512px;
        margin: 0 auto;
    }

    .comments {
        width: 512px;
        margin: 0 auto;
    }
}

.comments-form label {
    display: block;
    padding-bottom: 8px;
}

.comments-form input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 8px;
}

.comments-form textarea {
    display: block;
    width: 100%;
    min-height: 150px;
    box-sizing: border-box;
    margin-bottom: 8px;
}

.g-recaptcha {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
}

.comments-form .btn {
    display: block;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 16px;
    padding: 4px 12px;
}

.comments {
    border-bottom: 1px solid black;
}

.comment {
    /* box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5); */
    display: flex;
    margin-bottom: 16px;
    padding: 12px;
    position: relative;
}

.comment__avatar-wrapper {
    padding-right: 16px;
}

.comment__author {
    padding-top: 0;
    padding-bottom: 4px;
}

.comment__author a {
    text-decoration: none;
}

.comment__date {
    font-size: 0.9em;
    padding-bottom: 8px;
}

.comment__date a {
    text-decoration: none;
}

.comment__message {
    white-space: pre-wrap;
}

#comments-popup {
    padding: 12px;
    position: absolute;
    width: 70%;
    left: 15%;
    top: -999px;
    text-align: center;
    border: 1px solid $color1;
    background-color: #fff;
    transition: top 0.3s;
}

#comments-popup.is-visible {
    top: 0;
}
