* {
    padding: 0;
    margin: 0;
    line-height: 1em;
}

html, body {
    background-color: #fff;
    font-size: 16px;
    font-family: $formal-font;
}

h1, h2, h3 {
    padding: 16px 0;
}

p {
    padding-bottom: 8px;
    line-height: 1.2em;
}

p.share {
    text-align: left;
}

a {
    color: $color2;
}

body > header {
    background-color: $color1;
    color: $color4;
    padding: 8px 32px;
    text-align: center;
}

body > header a {
    color: $color3;
}

header .blog-name {
    font-family: $informal-font;
    font-size: 4em;
    letter-spacing: -1.5px;
}

header .subtitle {
    font-size: 1.2em;
}

body > nav {
    padding: 8px 32px;
}

nav a {
    color: $color1;
    padding-left: 8px;
}

body > section {
    padding: 0 32px;
}

section h1 {
    font-family: $informal-font;
    font-size: 3em;
    font-weight: normal;
    color: $color2;
}

.latest-posts {
    color: $color1;
}

.latest-posts a {
    color: $color2;
    text-decoration: none;
    font-weight: normal;
}

section .date {
    display: inline-block;
    font-style: italic;
    text-align: right;
    width: 100%;
}

blockquote {
    color: #555;
    margin: 16px;
    padding: 16px 8px 8px 24px;
    position: relative;
}

blockquote ::before {
    background: #c8ccd0;
    border-radius: 8px;
    content: "";
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
}

blockquote p {
     line-height: 1em;
}

.post-container {
    position: relative;
}

table th,
table td {
    border: 1px dotted gray;
    padding: 8px 4px;
}

ul {
    padding: 16px;
}

ul li {
    padding-bottom: 8px;
    list-style-type: disc;
}

ul.latest-posts li {
    list-style-type: none;
}
