.highlight {
    overflow-x: auto;
    margin: 16px 0;
    padding: 8px 8px 16px;
    background-color: #f7f7f7;
}

.highlighter-rouge {
    background-color: #f7f7f7;
    padding: 2px 4px;
}
